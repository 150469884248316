<template>
    <div id="video" v-loading="loading" :element-loading-text="$t('alarm.a0')">
            <!-- 页面标题 -->
        <div class="header">
          <span class="header-title">{{ kind==0?$t('video.viddata031') : $t('video.viddata032')}}</span>
          <div>
              <a-button type="primary" v-if="kind===0" @click="kindChage">{{ $t('video.viddata032') }}</a-button>
              <a-button type="primary" v-if="kind===1" @click="kindChage">{{ $t('video.viddata031') }}</a-button>
          </div>
        </div>
        <div class="content">
            <video-live :siteId="siteId" v-if="kind==0"></video-live>
            <video-play :siteId="siteId" v-else-if="kind==1" ></video-play>
        </div>
    </div>

</template>
<script>
import videoLive from "./live.vue";
import videoPlay from "./play.vue";

export default {

    data(){
        return {
            loading:false,
            kind:0,
            siteId: parseInt(this.$route.query.id),
        }
    },
    methods:{
        kindChage(){
            this.kind = this.kind==0?1:0;
        }
    },
    components:{
        "video-live": videoLive,
        "video-play": videoPlay,
    }

}
</script>
<style scoped>
#video {
    width: 100%;
    height: 100%;
    padding: 20px;
    font-family:apple-system, BlinkMacSystemFont, 'Segoe UI','PingFangSC-Regular', 'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol'
}
.header{
    display: flex;
    justify-content: space-between;
}
.header-title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 18px;
}
.header-title::before {
    content: "";
    display: inline-block;
    width: 2px;
    height: 22px;
    margin-right: 20px;
    background-color: #7682ce;
}
.content{
    width: 100%;
    height: 100%;
}
</style>