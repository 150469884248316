<template>
    <div id="videoPlay" ref="info" v-loading="loading" :element-loading-text="$t('alarm.a0')">
          <a-row >
                <a-col :span="5">
                    <div >
                        <div class="header-condition">
                            <span>{{$t('video.viddata030') }}</span>
                            <a-select v-model="cameraGroupId" style="width: 100%;" @change="cameraGrChange">
                              <a-select-option
                                  v-for="(itme, index) in cameraGroupList"
                                  :key="index"
                                  :value="itme.id"                      
                                  >
                                  {{ itme.name }}
                                </a-select-option>
                            </a-select>
                        </div>
                        <div class="header-condition">
                            <span>{{$t('video.viddata033') }}</span>
                            <!--   -->
                            <a-table :style="tstyle" 
                                :row-selection="rowSelection"
                                :columns="columns"
                                :data-source="cameraList"   
                                :row-key="record => record.id"                             
                                :pagination="pagination"
                                :scroll="tscroll"
                                size="small"
                            >
                            </a-table>
                        </div>
                    </div>
                </a-col>
                <a-col v-if="videoBrand!=12" :span="19" :style="{height:vhigh}">
                    <div  class="video">
                       <a-empty>
                         <span slot="description"> {{videoBrandTxt}}</span>
                       </a-empty>
                    </div>
                </a-col>
                <a-col v-if="videoBrand==12" :span="11" :style="{height:vhigh}">
                     <div v-if="videoBrand==12" class="video">
                        <div id="divPlaybackPlugin" class="plugin"></div>
                        <div class="video-tool-box" style="">
                            <!-- size="small" width:vwidth,  -->

                                <a-button v-if="pmode==0"      type="primary" icon="play-circle"  :title="$t('video.viddata046')"      class="video-tool-button"   @click="play"   :disabled="videoRecord == null"/>
                                <a-button v-else-if="pmode==1" type="primary" icon="pause-circle"  :title="$t('video.viddata047')"     class="video-tool-button"    @click="pause"  :disabled="videoRecord == null"/>
                                <a-button v-else-if="pmode==2" type="primary" icon="redo"  :title="$t('video.viddata057')"    class="video-tool-button"    @click="resume" :disabled="videoRecord == null" />
                               
                                <a-button type="primary" icon="stop"            :title="$t('video.viddata048')"     class="video-tool-button"    @click="stop"       :disabled="pmode==0" />
                                <a-button type="primary" icon="fast-backward"   :title="$t('video.viddata049')"     class="video-tool-button"    @click="slowPlay"   :disabled="videoRecord == null || pmode==0"/>
                                <a-button type="primary" icon="fast-forward"    :title="$t('video.viddata050')"     class="video-tool-button"    @click="quickPlay"  :disabled="videoRecord == null || pmode==0"/>

                                <a-button type="primary" icon="fullscreen"  :title="$t('video.viddata038')"     class="video-tool-button"    @click="fullscreen" :disabled="pmode==0"/>
                                <a-button type="primary" icon="plus-circle" :title="$t('video.viddata056')" :class="(zoom==1?'video-tool-button-active':'video-tool-button')"    @click="zoomEnable" :disabled="pmode==0"/>
                                <a-button type="primary" icon="camera"      :title="$t('video.viddata043')"     class="video-tool-button"    @click="capturePic" :disabled="pmode==0"/>
                                <a-button type="primary" icon="download"    :title="$t('video.viddata003')"     class="video-tool-button"    @click="download"   :disabled="(videoRecord == null || this.g_iDownloadID>0)"/>
                                <div class="prossed-box" v-if="this.g_iDownloadID>0">
                                    <a-progress type="circle" :percent="30" :width="32" />
                                </div>
                        </div>
                    </div>
                </a-col>
                 <a-col v-if="videoBrand==12" :span="8">
                    <div class="info">
                        <div class="header">
                            <div class="header-condition">
                                <span>{{$t('energy.enedata350') }}</span>
                                <a-date-picker
                                  v-model="recordDate"
                                  format="YYYY-MM-DD"
                                  :disabled-date="disabledDate"
                                  :disabled="camera==null"
                                />
                            </div>
                            <div class="header-button">
                                  <a-button type="primary"  :disabled="camera==null" @click="recordSearch(0)" >{{ $t('energy.enedata006') }}</a-button>
                            </div>
                        </div>
                          <div class="header-condition">
                            <span>{{$t('video.viddata034') }}</span>
                            <!--   -->
                            <a-table :style="tstyle" 
                                :row-selection="videoSelection"
                                :columns="columns2"
                                :data-source="videoList"                              
                                :pagination="pagination"
                                :scroll="rscroll"
                                size="small"
                            >
                            </a-table>
                        </div>
                    </div>
                 </a-col>

          </a-row>
    </div>
</template>
<script>
import { getVideoCameraSet,getVideoCameraList } from "../../api/video";
import moment from "moment";
export default {
    props: {
        siteId: {
            type: Number,
            default: 0,
        }
    }, 
    data(){
        return {
            loading:false,
            camera:null,
            selectedRows:[],
            selectedRowKeys:[],
            columns:[ 
                {title: 'No.'		                     , dataIndex: 'key'  ,width:50  }, 
                {title: this.$t('video.viddata006')		 , dataIndex: 'name'  }, 
            ],
            columns2:[ 
                {title: this.$t('video.viddata058')		 , dataIndex: 'name'      ,width:120   }, 
                {title: this.$t('video.viddata059')		 , dataIndex: 'startTime' ,width:120   }, 
                {title: this.$t('video.viddata060')		 , dataIndex: 'endTime'   ,width:120   }, 
            ],
            tscroll:{x:400},
            tstyle:{width:'100%',height:'100%'},
            pagination:false,
            cameraList:[],
            cameraGroupList:[],
            cameraGroupId:'',

            recordDate:moment().subtract(1,"days"),
            videoList:[],
            vselectedRowKeys:[],
            rscroll:{x:600},
            videoBrand:0,
            videoBrandTxt:this.$t('video.viddata061'),

            isVideoLogin:false,
            loginIpPort:'',
            videoWindNum : 1,
            recorder:null,
            videoRecord:null,
            
            g_iWndIndex:0,
            opts:{
                width:'100%',
                height:'100%',
                bWndFull: true,     //是否支持单窗口双击全屏，默认支持 true:支持 false:不支持
                iPackageType: 2, 
                szColorProperty:"",//"plugin-background:0000ff; sub-background:0000ff; sub-border:00ffff; sub-border-select:0000ff",   //2:PS 11:MP4
                iWndowType: 1,
                bNoPlugin: true,
            },

            streamType : 1,
            rtspPort : 554,
            zeroChannel : false,

            zoom:0,
            zoomWind:-1,
            vhigh:0,
            vwidth:0,
            g_iSearchTimes:0,
            pmode:0,
            g_iDownloadID:-1,
            g_tDownloadProcess:0,

            protocol:2,//1:http;2:https
        }
    },
    computed: {
        rowSelection() {
            const { selectedRowKeys } = this;
            return {
                hideDefaultSelections:true,
                selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                    this.selectedRowKeys=selectedRowKeys;
                },
                onSelect:(record, selected, selectedRows, nativeEvent) =>{
                    this.loading = true;
                    this.videoRecord = null;
                    this.videoList = new Array();
                    if(this.videoBrand>0){

                      this.camera = record;
                      if( this.protocol === 2){
                          this.camera.recorderConnectPort = 443;
                      }
                      this.stop();
                    }
                    this.loading = false;
                },
                type:'radio',
            };
        },
        videoSelection() {
            const { vselectedRowKeys } = this;
            return {
                hideDefaultSelections:true,
                vselectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                    this.vselectedRowKeys=selectedRowKeys;
                },
                onSelect:(record, selected, selectedRows, nativeEvent) =>{
                    this.loading = true;
                    this.videoRecord = record;
                    this.stop();
                    this.play();
                    // this.loading = false;
                },
                type:'radio',
            };
        }
    },
    activated() {
        // if(document.getElementById('cbinfo'))document.getElementById('cbinfo').innerHTML = '';
        this.isVideoLogin = false;
        this.recorder = null;
        let sprotocol = location.protocol ;
        if(sprotocol==='https:'){
            this.protocol = 2;
        }else{
            this.protocol = 1;
        }
        console.log('play activated location.protocol',this.protocol);
    },
    mounted() { 
        this.initPage();  
       
        // 调用表格高度计算方法
        this.pageSize();
        // 根据窗口变化计算表格高度
        window.addEventListener("resize", this.pageSize);
        let sprotocol = location.protocol ;
        if(sprotocol==='https:'){
            this.protocol = 2;
        }else{
            this.protocol = 1;
        }
        console.log('play mounted location.protocol',this.protocol);

    },
    deactivated(){
        this.showCBInfo('deactivated',moment().format('YYYY/MM/DD HH:mm:ss.SSS'));
        this.close();
    },
    destroyed(){
        this.showCBInfo('destroyed',moment().format('YYYY/MM/DD HH:mm:ss.SSS'));
        this.close();
        window.removeEventListener("resize", this.pageSize);
    },
    beforeDestroy() {
       
    },
    methods:{
        showCBInfo(szInfo){
            // szInfo = "<div>" + moment().format('YYYY/MM/DD HH:mm:ss.SSS') + " " + szInfo + "</div>";
            szInfo =  moment().format('YYYY/MM/DD HH:mm:ss.SSS') + " " + szInfo ;
            console.log(szInfo);
        },
        videoInit(options){
            let that = this;
            //检查浏览器是否支持无插件
            let bol = window.WebVideoCtrl.I_SupportNoPlugin();
            this.showCBInfo('检查浏览器是否支持无插件:'+bol);
            options.bNoPlugin = bol;
            //检查插件是否已经安装过
            let iRet = window.WebVideoCtrl.I_CheckPluginInstall();
            this.showCBInfo('检查插件是否已经安装过:'+iRet);
            if (-1 == iRet) {
                // alert("您还未安装过插件，双击开发包目录里的WebComponentsKit.exe安装！");
                this.$message.error(this.$t('viddata062'));
                return;
            }
            // 初始化插件参数及插入插件
            WebVideoCtrl.I_InitPlugin(options.width,options.height, {
                szColorProperty:options.szColorProperty,  
                iWndowType: options.iWndowType,
                bNoPlugin: options.bNoPlugin,
                cbSelWnd: function (xmlDoc) {
                    const xml = that.$x2js.dom2js(xmlDoc) 
                    that.g_iWndIndex = xml.RealPlayInfo.SelectWnd;
                    let szInfo = "当前选择的窗口编号：" + that.g_iWndIndex;
                    that.showCBInfo(szInfo);
                },
                cbInitPluginComplete: function () {
                    WebVideoCtrl.I_InsertOBJECTPlugin("divPlaybackPlugin");

                    if(!that.isVideoLogin && that.cameraList.length>0 && that.cameraList[0] !=null){
                        that.checkLogin(that.cameraList[0]);
                    }
                }
            });
        },
        checkLogin(camera,callback,param){
            let that = this;
            this.showCBInfo('checkLogin',camera);
            let port = camera.recorderConnectPort;
            if(this.protocol === 2 ){
                port = 443;
            }
            if(camera){
                camera.recorderConnectPort = port;
                this.recorder = {
                    ip:camera.recorderIpAddress,
                    port:port,
                    userid: camera.monitoringUserId,
                    pass:camera.monitoringUserPassword,
                    brand:camera.cameraType,
                };

            }
        
            if ("" == this.recorder.ip || "" == this.recorder.port) {
                return false;
            }
            this.showCBInfo("I_Login recorder",this.recorder);
            let szDeviceIdentify = this.recorder.ip + "_" + this.recorder.port;
            let iRet = WebVideoCtrl.I_Login(this.recorder.ip, this.protocol, this.recorder.port, this.recorder.userid, this.recorder.pass, {
                success: function (xmlDoc) { 
                    that.isVideoLogin = true;
                    that.loginIpPort = szDeviceIdentify;
                    that.showCBInfo(szDeviceIdentify + " 登录成功！");
                    if(callback){
                        callback(param);
                    }
                },
                error: function (status, xmlDoc) {
                    that.isVideoLogin = false;
                    that.loginIpPort = '';
                    that.showCBInfo(szDeviceIdentify + "登录失败！" + status);
                }
            });

            if (-1 == iRet) {
                that.isVideoLogin = true;
                that.loginIpPort = szDeviceIdentify;
                // that.showCBInfo(szDeviceIdentify + " 已登录过！");
            }
        },
        clickLogout(){
            if(this.recorder){
                let szDeviceIdentify =  this.recorder.ip + "_" + this.recorder.port; //$("#ip").val(),
                let szInfo = "";
                if (null == szDeviceIdentify) {
                    return;
                }
                let iRet = WebVideoCtrl.I_Logout(szDeviceIdentify);
                if (0 == iRet) {
                    this.isVideoLogin = false;
                    this.loginIpPort = '';
                    this.recorder = null;
                    szInfo = "退出成功！";
                } else {
                    szInfo = "退出失败！";
                }
                this.showCBInfo(szDeviceIdentify + " " + szInfo);
            }
        },
        recordSearch(iType) {
            let port = this.camera.recorderConnectPort;
            if(this.protocol === 2 ){
                this.camera.recorderConnectPort = 443;
            }
            var szDeviceIdentify = this.camera.recorderIpAddress + "_" + this.camera.recorderConnectPort,
                iChannelID = parseInt(this.camera.recorderChannelNo),
                bZeroChannel = this.bZeroChannel,
                iStreamType = parseInt(this.streamType),
                szStartTime = this.recordDate.format('YYYY-MM-DD') +" 00:00:00",
                szEndTime =  this.recordDate.format('YYYY-MM-DD') +" 23:59:59";
            
            if (null == szDeviceIdentify) {
                return;
            }
            if(this.loginIpPort!==szDeviceIdentify){
                    if(this.isVideoLogin){
                        this.stop();
                        let iRet = WebVideoCtrl.I_Logout(szDeviceIdentify);
                        if (0 == iRet) {
                            this.isVideoLogin = false;
                            this.loginIpPort = '';
                            this.recorder = null;
                            this.checkLogin(this.camera,this.recordSearch,0);
                        } else {
                            szInfo = "退出失败！";
                        }
                    }else{
                        this.checkLogin(this.camera,this.recordSearch,0);
                    }
                    return;
            } else {
                if(!this.isVideoLogin){
                    this.checkLogin(this.camera,this.recordSearch,0);
                    return;
                }
            }
           

            if (bZeroChannel) {// 零通道不支持录像搜索
                return;
            }
            let that = this;
            if (0 == iType) {// 首次搜索
                that.videoList = new Array();
                that.g_iSearchTimes = 0;
            }
            that.loading = true;
            that.showCBInfo(szDeviceIdentify, iChannelID, szStartTime, szEndTime,iStreamType,that.g_iSearchTimes);
            WebVideoCtrl.I_RecordSearch(szDeviceIdentify, iChannelID, szStartTime, szEndTime, {
                iStreamType: iStreamType,
                iSearchPos: that.g_iSearchTimes * 40,
                success: function (xmlDoc) {
                    let res = that.$x2js.dom2js(xmlDoc);
                    that.showCBInfo('RecordSearch result',res,res.CMSearchResult.responseStatusStrg);
                    let data  = res.CMSearchResult;
                    let status = res.CMSearchResult.responseStatusStrg;
                    if("MORE" === status){
                        for (let i = 0; i < data.matchList.searchMatchItem.length; i++) {
                            const element = data.matchList.searchMatchItem[i];
                            var szPlaybackURI = element.playbackURI;
                            if(szPlaybackURI.indexOf("name=") < 0) {
                                break;
                            }
                            var szStartTime = element.startTime;
                            var szEndTime = element.endTime;
                            var szFileName = szPlaybackURI.substring(szPlaybackURI.indexOf("name=") + 5, szPlaybackURI.indexOf("&size="));

                            let record = {
                                key :szFileName,
                                name:szFileName,
                                startTime:szStartTime,
                                endTime:szEndTime,
                                playbackURI:szPlaybackURI,
                            };

                            that.videoList.push(record);
                        }
                        that.g_iSearchTimes++;
                        that.recordSearch(1);
                    }else if("OK" === status){
                       that.showCBInfo('RecordSearch searchMatchItem', data.matchList.searchMatchItem);
                        for (let i = 0; i < data.matchList.searchMatchItem.length; i++) {
                            const element = data.matchList.searchMatchItem[i];
                            let szPlaybackURI = element.playbackURI;
                            that.showCBInfo(typeof szPlaybackURI);
                            if(!szPlaybackURI || szPlaybackURI.indexOf("name=") < 0) {
                                break;
                            }
                            let szStartTime = element.startTime;
                            let szEndTime = element.endTime;
                            let szFileName = szPlaybackURI.substring(szPlaybackURI.indexOf("name=") + 5, szPlaybackURI.indexOf("&size="));

                            let record = {
                              key :szFileName,
                              name:szFileName,
                              startTime:szStartTime,
                              endTime:szEndTime,
                              playbackURI:szPlaybackURI,
                            };

                            that.videoList.push(record);
                        }
                        that.showCBInfo(szDeviceIdentify + " 搜索录像文件成功！");
                        that.loading = false;
                    }else if("NO MATCHES" === status){
                      setTimeout(function() {
                          that.showCBInfo(szDeviceIdentify + " 没有录像文件！");
                           that.loading = false;
                      }, 50);
                    }
                    
                },
                error: function (status, xmlDoc) {
                    that.showCBInfo(szDeviceIdentify + " 搜索录像文件失败！", status, xmlDoc);
                    that.loading = false;
                }
            });
        },
        playback(szDeviceIdentify,iRtspPort,iStreamType,iChannelID,szStartTime,szEndTime){
            let szInfo = '';
            let that = this;
            WebVideoCtrl.I_StartPlayback(szDeviceIdentify, {
                iRtspPort: iRtspPort,
                iStreamType: iStreamType,
                iChannelID: iChannelID,
                szStartTime: szStartTime,
                szEndTime: szEndTime,
                success: function () {
                    szInfo = "开始回放成功！";
                    that.showCBInfo(szDeviceIdentify + " " + szInfo);
                    that.pmode = 1;
                    that.loading = false;
                },
                error: function (status, xmlDoc) {
                    if (403 === status) {
                        // szInfo = "设备不支持Websocket取流！";
                        szInfo = "开始回放失败！";
                    } else {
                        szInfo = "开始回放失败！";
                    }
                    that.showCBInfo(szDeviceIdentify + " " + szInfo);
                    that.loading = false;
                }
            });
        },
        reversePlayback(szDeviceIdentify,iRtspPort,iStreamType,iChannelID,szStartTime,szEndTime){
            let szInfo = '';
            var iRet = WebVideoCtrl.I_ReversePlayback(szDeviceIdentify, {
                iRtspPort: iRtspPort,
                iStreamType: iStreamType,
                iChannelID: iChannelID,
                szStartTime: szStartTime,
                szEndTime: szEndTime
             });

              if (0 == iRet) {
                  szInfo = "开始倒放成功！";
                  this.pmode = 1;
              } else {
                  szInfo = "开始倒放失败！";
              }
              this.showCBInfo(szDeviceIdentify + " " + szInfo);
        },
        play(){
            if(this.camera && this.videoRecord){

                var oWndInfo = WebVideoCtrl.I_GetWindowStatus(this.g_iWndIndex);
                let szDeviceIdentify = this.camera.recorderIpAddress +'_'+this.camera.recorderConnectPort,
                    iRtspPort = parseInt(this.rtspPort),
                    iStreamType = parseInt(this.streamType),
                    bZeroChannel = this.zeroChannel,
                    iChannelID = parseInt(this.camera.recorderChannelNo),
                    szStartTime =  this.videoRecord.startTime,
                    szEndTime = this.videoRecord.endTime,
                    szInfo = "",
                    bChecked = false,
                    iRet = -1;

                if (null == szDeviceIdentify) {
                    return;
                }

                if (bZeroChannel) {// 零通道不支持回放
                    return;
                }
                this.loading = true;
                this.showCBInfo('playback params',szDeviceIdentify,iRtspPort,iStreamType,iChannelID,szStartTime,szEndTime)
                if (oWndInfo != null) {// 已经在播放了，先停止
                    let that = this;
                    WebVideoCtrl.I_Stop({
                        success: function () {
                            that.playback(szDeviceIdentify,iRtspPort,iStreamType,iChannelID,szStartTime,szEndTime);
                        }
                    });
                } else {
                    this.playback(szDeviceIdentify,iRtspPort,iStreamType,iChannelID,szStartTime,szEndTime);
                }

            }
        },
        stop(){
            if(this.zoom>0){
              this.zoomEnable();
            }
            WebVideoCtrl.I_Stop();
            this.pmode = 0;
            this.zoom = 0;
        },
        reversePlay(){
            if(this.camera && this.videoRecord){
                var oWndInfo = WebVideoCtrl.I_GetWindowStatus(this.g_iWndIndex);
                let szDeviceIdentify = this.camera.recorderIpAddress +'_'+this.camera.recorderConnectPort,
                    iRtspPort = parseInt(this.rtspPort),
                    iStreamType = parseInt(this.streamType),
                    bZeroChannel = this.zeroChannel,
                    iChannelID = parseInt(this.camera.recorderChannelNo),
                    szStartTime =  this.videoRecord.startTime,
                    szEndTime = this.videoRecord.endTime,
                    szInfo = "",
                    bChecked = false,
                    iRet = -1;

                if (null == szDeviceIdentify) {
                    return;
                }

                if (bZeroChannel) {// 零通道不支持回放
                    return;
                }
                
                this.showCBInfo('reversePlayback params',szDeviceIdentify,iRtspPort,iStreamType,iChannelID,szStartTime,szEndTime)
                if (oWndInfo != null) {// 已经在播放了，先停止
                    let that = this;
                    WebVideoCtrl.I_Stop({
                        success: function () {
                            that.reversePlayback(szDeviceIdentify,iRtspPort,iStreamType,iChannelID,szStartTime,szEndTime);
                        }
                    });
                } else {
                    this.reversePlayback(szDeviceIdentify,iRtspPort,iStreamType,iChannelID,szStartTime,szEndTime);
                }
            }
        },
        slowPlay(){
            var oWndInfo = WebVideoCtrl.I_GetWindowStatus(this.g_iWndIndex),
                szInfo = "";

            if (oWndInfo != null) {
                let that = this;
                WebVideoCtrl.I_PlaySlow({
                    success: function () {
                        szInfo = "慢放成功！";
                        that.showCBInfo(oWndInfo.szDeviceIdentify + " " + szInfo);
                    },
                    error: function () {
                        szInfo = "慢放失败！";
                        that.showCBInfo(oWndInfo.szDeviceIdentify + " " + szInfo);
                    }
                });
            }
        },
        quickPlay(){
            var oWndInfo = WebVideoCtrl.I_GetWindowStatus(this.g_iWndIndex),
                szInfo = "";
            if (oWndInfo != null) {
                let that = this;
                WebVideoCtrl.I_PlayFast({
                    success: function () {
                        szInfo = "快放成功！";
                        that.showCBInfo(oWndInfo.szDeviceIdentify + " " + szInfo);
                    },
                    error: function () {
                        szInfo = "快放失败！";
                        that.showCBInfo(oWndInfo.szDeviceIdentify + " " + szInfo);
                    }
                });
            }
        },
        pause() {
            var oWndInfo = WebVideoCtrl.I_GetWindowStatus(this.g_iWndIndex),
                szInfo = "";
            let that = this;
            if (oWndInfo != null) {
                WebVideoCtrl.I_Pause({
                    success: function () {
                        szInfo = "暂停成功！";
                        that.showCBInfo(oWndInfo.szDeviceIdentify + " " + szInfo);
                        that.pmode = 2;
                    },
                    error: function () {
                        szInfo = "暂停失败！";
                        that.showCBInfo(oWndInfo.szDeviceIdentify + " " + szInfo);
                    }
                });
            }
        },
        resume() {
            var oWndInfo = WebVideoCtrl.I_GetWindowStatus(this.g_iWndIndex),
                szInfo = "";
            let that = this;
            if (oWndInfo != null) {
                WebVideoCtrl.I_Resume({
                    success: function () {
                        szInfo = "恢复成功！";
                        that.showCBInfo(oWndInfo.szDeviceIdentify + " " + szInfo);
                        that.pmode = 1;
                    },
                    error: function () {
                        szInfo = "恢复失败！";
                        that.showCBInfo(oWndInfo.szDeviceIdentify + " " + szInfo);
                    }
                });
            }
        },
        download(){
            var szDeviceIdentify = this.camera.recorderIpAddress +'_'+this.camera.recorderConnectPort,
                szChannelID = this.camera.recorderChannelNo,
                szFileName = this.videoRecord.name,
                szPlaybackURI = this.videoRecord.playbackURI;

            if (null == szDeviceIdentify) {
                return;
            }

            this.g_iDownloadID = WebVideoCtrl.I_StartDownloadRecord(szDeviceIdentify, szPlaybackURI, szFileName, {
                bDateDir: true  //是否生成日期文件
            });

            if (this.g_iDownloadID < 0) {
                var iErrorValue = WebVideoCtrl.I_GetLastError();
                if (34 == iErrorValue) {
                    this.showCBInfo(szDeviceIdentify + " 已下载！");
                } else if (33 == iErrorValue) {
                    this.showCBInfo(szDeviceIdentify + " 空间不足！");
                } else {
                    this.showCBInfo(szDeviceIdentify + " 下载失败！");
                }
            } else {
                //$("<div id='downProcess' class='freeze'></div>").appendTo("body");
                this.loading = true;
                this.g_tDownloadProcess = setInterval(this.downProcess(), 1000);
            }
        },
        downProcess() {
            var iStatus = WebVideoCtrl.I_GetDownloadStatus(this.g_iDownloadID);
            if (0 == iStatus) {
                this. iProcess = WebVideoCtrl.I_GetDownloadProgress(this.g_iDownloadID);
                if (this.iProcess < 0) {
                    clearInterval(this.g_tDownloadProcess);
                    this.g_tDownloadProcess = 0;
                    this.g_iDownloadID = -1;
                } else if (iProcess < 100) {
                   // this.downProcessTxt = iProcess + "%";
                } else {
                    this.iProcess = 100;
                    WebVideoCtrl.I_StopDownloadRecord(this.g_iDownloadID);

                    this.showCBInfo("录像下载完成！");
                    clearInterval(this.g_tDownloadProcess);
                    this.g_tDownloadProcess = 0;
                    this. g_iDownloadID = -1;
                    this.loading = false;
                }
            } else {
                WebVideoCtrl.I_StopDownloadRecord(this.g_iDownloadID);
                this.iProcess = 0;
                clearInterval(this.g_tDownloadProcess);
                this.g_tDownloadProcess = 0;
                this.g_iDownloadID = -1;
                this.loading = false;
            }
        },
        zoomEnable(){
            let oWndInfo = WebVideoCtrl.I_GetWindowStatus(this.g_iWndIndex),
                szInfo = "";
            if(this.zoom==0){
                if (oWndInfo != null) {
                    let iRet = WebVideoCtrl.I_EnableEZoom();
                    if (0 == iRet) {
                        szInfo = "启用电子放大成功！";
                        this.zoom =1;
                    } else {
                        szInfo = "启用电子放大失败！";
                    }
                    this.showCBInfo(oWndInfo.szDeviceIdentify + " " + szInfo);
                }
                
            }else{
                if (oWndInfo != null) {
                    let iRet = WebVideoCtrl.I_DisableEZoom();
                    if (0 == iRet) {
                        szInfo = "禁用电子放大成功！";
                        this.zoom = 0;
                    } else {
                        szInfo = "禁用电子放大失败！";
                    }
                    this.showCBInfo(oWndInfo.szDeviceIdentify + " " + szInfo);
                }
            }
        },
        capturePic(){
            let szInfo = "";
            let oWndInfo = WebVideoCtrl.I_GetWindowStatus(this.g_iWndIndex);
            if (oWndInfo != null) {
                let xmlDoc = WebVideoCtrl.I_GetLocalCfg();
                let szCaptureFileFormat = "0";
                if (xmlDoc != null) {
                    const xml = that.$x2js.dom2js(xmlDoc);
                    szCaptureFileFormat = xml.CaptureFileFormat;
                }

                let szPicName = this.camera.name + "_" + moment().format('YYYYMMDDHHmmssSSS');
                szPicName += ("0" === szCaptureFileFormat) ? ".jpg" : ".bmp";

                WebVideoCtrl.I2_CapturePic(szPicName, {
                    bDateDir: true  //是否生成日期文件
                }).then(function(){
                    szInfo = "抓图成功！";
                    this.showCBInfo(oWndInfo.szDeviceIdentify + " " + szPicName + " " + szInfo);
                },function(){
                    szInfo = "抓图失败！";
                    this.showCBInfo(oWndInfo.szDeviceIdentify + " " + szPicName + " " + szInfo);
                });
            }
        },
        fullscreen(){
            WebVideoCtrl.I_FullScreen(true);
            this.showCBInfo(" 全屏设置！");
        },
        close(){
            if(this.videoBrand!=0){
                WebVideoCtrl.I_FullScreen(false);
                this.stop();
                this.clickLogout() ;
            }
        },
        initPage(){
            this.getCameraSet();
        },
        cameraGrChange(e){
            this.stop();
            this.getCameraList();
        },
        pageSize(){
            let height = this.$refs.info.clientHeight;
            this.showCBInfo("height",height);
            let tableHeight = height - 160;
            this.tscroll.y = tableHeight;
            this.rscroll.y = tableHeight;
            let width = this.$refs.info.clientWidth;
            this.vhigh  = (height-60)  + "px";
            this.showCBInfo(this.tscroll);
        },
        getCameraSet(){
            this.loading = true;
            getVideoCameraSet(this.siteId)
                .then((res) => {
                this.showCBInfo("res",res);
                if(res.errorCode==='00'){
                    this.cameraGroupList = res.data;
                    this.cameraGroupId = this.cameraGroupList[0].id;
                    this.getCameraList();
                }
                this.loading = false;
            }).catch((err) => {
                this.showCBInfo(err);
                this.loading = false;
            });
        },
        getCameraList(){
            this.videoList = new Array();
            this.camera = null;
            this.recordDate = moment();

            this.loading = true;
            getVideoCameraList(this.cameraGroupId)
                .then((res) => {
                this.showCBInfo("res",res);
                if(res.errorCode==='00'){
                    this.cameraList = res.data;
                    for (let i = 0; i < this.cameraList.length; i++) {
                       this.cameraList[i].key=(i+1);
                    }
                    // if(this.videoBrand===0){
                    //     let brand = this.cameraList[0].cameraType;
                    //     if(brand == 12){
                    //       this.videoBrand = brand;
                    //       this.recorder = {
                    //           ip:this.cameraList[0].recorderIpAddress,
                    //           port:this.cameraList[0].recorderConnectPort,
                    //           userid: this.cameraList[0].monitoringUserId,
                    //           pass:this.cameraList[0].monitoringUserPassword,
                    //           brand:this.cameraList[0].cameraType,
                    //       };
                    //       this.videoInit(this.opts);
                    //     }
                    // }else if(!this.isVideoLogin){
                    //      this.checkLogin(this.cameraList[0]);
                    // }

                    if(this.videoBrand==0 && this.cameraList[0].cameraType==12){
                         this.videoBrand = this.cameraList[0].cameraType;
                         this.videoInit(this.opts);
                    }else if(this.videoBrand==12 && !this.isVideoLogin){
                         this.checkLogin(this.cameraList[0]);
                    }
                }
                this.loading = false;
            }).catch((err) => {
                this.showCBInfo(err);
                this.loading = false;
            });
        },
        disabledDate(current){
          return current && current > moment().endOf('day');
        },
        
        
    }
}
</script>
<style scoped>
#videoPlay{
    width: 100%;
    height: 100%;
}
.header{
    display: flex;
    justify-content: space-between;
}
.header-title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 18px;
}
.header-title::before {
    content: "";
    display: inline-block;
    width: 2px;
    height: 22px;
    margin-right: 20px;
    background-color: #7682ce;
}
.header-button{
    display: flex;
    align-items: center;
}
.header-condition {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
}
.header-condition button{
  margin-bottom: 5px;
}
.header-condition span{
  margin-bottom: 5px;
}
.high{
   height: calc( 100% - 10px ); 
}
.info{
    width: 100%;
    height: 100%;
    padding: 10px;
}
.video{
    width: 100%;
    height: 100%;
    padding: 10px;
}
.plugin{
    width: 100%;
    height: 100%;
    padding: 0 10px;
    
}

.video-tool-box{
    margin:0 10px;padding:5px 0;background-color: black;
}
.video-tool-button{
    color: rgba(0, 0, 0, 1);
    background-color: white;
    border-color: white;
    text-shadow: none;
    box-shadow: none;
    margin-left:5px;
}
.video-tool-button[disabled],.video-tool-button[disabled]:hover{
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
}
.video-tool-button-active{
    background-color: white;
    border-color: #d9d9d9;
    color: red;
    text-shadow: none;
    box-shadow: none;
    margin-left:5px;
}
.video-tool-button:hover{
    background-color: #7682CE;
    border-color: #7682CE;
    color: rgba(255, 255, 255, 1);
    text-shadow: none;
    box-shadow: none;
}
.prossed-box{
  background-color: white;
  border-color: #d9d9d9;
  text-shadow: none;
  box-shadow: none;
  width: 32px;
  height: 32px;
  margin-left:5px;
}
</style>
