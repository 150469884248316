<template>
    <div id="videoLive" ref="info" v-loading="loading" :element-loading-text="$t('alarm.a0')">
        <!-- 页面标题 -->
        <a-row>
            <a-col :span="5">
                <div>
                    <div class="header-condition">
                        <span>{{ $t('video.viddata030') }}</span>
                        <a-select v-model="cameraGroupId" style="width: 100%;" @change="cameraGrChange">
                            <a-select-option v-for="(itme, index) in cameraGroupList" :key="index" :value="itme.id">
                                {{ itme.name }}
                            </a-select-option>
                        </a-select>
                    </div>
                    <div class="header-condition" ref="videotb">
                        <span>{{ $t('video.viddata033') }}</span>

                        <a-table :style="tstyle" :row-selection="radioRowSelection" :columns="columns"
                            :data-source="cameraList" :row-key="record => record.id" :pagination="pagination"
                            :scroll="tscroll" size="small">
                        </a-table>
                    </div>
                </div>
            </a-col>
            <a-col :span="19" :style="{ height: vhigh }">
                <!-- 海康 -->
                <div v-if="videoBrand === 12" class="video">
                    <div id="divRealPlayPlugin" class="plugin"></div>
                    <div class="video-tool-box" style="">
                        <!-- size="small" width:vwidth,  -->
                        <a-button type="primary" icon="fullscreen" :title="$t('video.viddata038')"
                            class="video-tool-button" @click="fullscreen" />
                        <a-button type="primary" icon="camera" :title="$t('video.viddata043')" class="video-tool-button"
                            @click="capturePic()" />
                        <a-button type="primary" icon="zoom-in" :title="$t('video.viddata056')"
                            :class="(zoom == 1 ? 'video-tool-button-active' : 'video-tool-button')" @click="zoomEnable()" />

                        <a-button type="primary" icon="stop" :title="$t('video.viddata048')" class="video-tool-button"
                            @click="stopAll" />
                        <!--
                                 <a-button type="primary" icon="border"      :title="$t('video.viddata039')" :class="(opts.iWndowType==1?'video-tool-button-active':'video-tool-button')"   @click="playwind(1)"/>
                                <a-button type="primary" icon="appstore"    :title="screen4"  :class="(opts.iWndowType==2?'video-tool-button-active':'video-tool-button')"    @click="playwind(2)"/>
                                <a-button type="primary" icon="table"       :title="screen9"  :class="(opts.iWndowType==3?'video-tool-button-active':'video-tool-button')"    @click="playwind(3)"/>
                                <a-button type="primary" icon="global"      :title="screen16" :class="(opts.iWndowType==4?'video-tool-button-active':'video-tool-button')"    @click="playwind(4)"/>

                                <a-button type="primary" icon="video-camera"   title="录像"  :class="(g_szRecordType=='realplay'?'video-tool-button-active':'video-tool-button')"   @click="videoRecorder()"/>
                             -->

                    </div>
                </div>
                <!-- 萤石云 -->
                <div v-else-if="videoBrand === 14" class="video-ys">
                    <!-- <ysplayer :camera-id="cameraId"></ysplayer> -->
                    <ysplayer v-if="cameraId > 0" :cameraId="cameraId" :dialog="dialog" :mode="mode"
                        :autoClose="autoClose" :vdate="date.format('YYYY-MM-DD')"
                        :stime="star.format('YYYY-MM-DD HH:mm:ss')" :etime="end.format('YYYY-MM-DD HH:mm:ss')"
                        ref="ysplayer" fluent @screenshot="shot" @destroy="destroy" />
                </div>
                <!-- 本地推流 -->
                <div v-else-if="videoBrand === 13" class="video">
                    <player ref="player" videoUrl="" fluent @screenshot="shot" :removeCheck="removeCheck"
                        @destroy="destroy" />
                </div>
                <!-- 其他 -->
                <div v-else class="video">
                    <a-empty>
                        <span slot="description"> {{ videoBrandTxt }}</span>
                    </a-empty>
                </div>
            </a-col>
        </a-row>
    </div>
</template>
<script>
import { getVideoCameraSet, getVideoCameraList, proxySave, getStreamInfo } from "../../api/video";
// import jQuery from "jquery"
import player from '../../components/video/jessibuca.vue'
import ysplayer from './components/ysplayer.vue'
import moment from "moment";
export default {
    components: {
        player,
        ysplayer
    },
    props: {
        siteId: {
            type: Number,
            default: 0,
        }
    },
    data() {
        return {
            loading: false,
            cameraId: 0,
            selectedRows: [],
            selectedRowKeys: [],
            columns: [
                { title: 'No.', dataIndex: 'key', width: 50 },
                { title: this.$t('video.viddata006'), dataIndex: 'name' },
            ],
            tscroll: { x: 400 },
            tstyle: { width: '100%', height: '100%' },
            pagination: false,
            cameraList: [],
            cameraGroupList: [],
            cameraGroupId: '',

            videoBrand: 0,
            videoTag: 0,
            videoBrandTxt: this.$t('video.viddata061'),
            isVideoLogin: false,
            loginIpPort: '',
            curtVideoWindNo: 0,
            nextVideoWindNo: 0,
            videoWindNum: 1,
            videoWindCameraMap: new Map(),
            videoCameraWindMap: new Map(),
            recorder: null,
            recorderIP: '',
            cWebVideoHK: null,
            SelectWnd: 0,
            g_iWndIndex: 0,
            opts: {
                width: '100%',
                height: '100%',
                bWndFull: true,     //是否支持单窗口双击全屏，默认支持 true:支持 false:不支持
                iPackageType: 2,  //2:PS 11:MP4
                szColorProperty: "",//"plugin-background:0000ff; sub-background:0000ff; sub-border:00ffff; sub-border-select:0000ff",
                iWndowType: 1,
                bNoPlugin: false,
            },
            streamType: 1,
            rtspPort: 554,
            zeroChannel: false,
            g_szRecordType: '',//开始录像标志
            recordWind: -1,
            zoom: 0,
            zoomWind: -1,
            vhigh: 0,
            vwidth: 0,
            chktype: 'checkbox',
            screen4: '',
            screen9: '',
            screen16: '',
            ploaded: 0,

            mode: '1',
            date: new moment(),
            star: new moment().startOf('day'),
            end: new moment(),
            dialog: 1,
            autoClose: 0,
            protocol: 2,//1:http;2:https

        }
    },
    computed: {
        radioRowSelection() {
            const { selectedRowKeys } = this;
            return {
                hideDefaultSelections: true,
                selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                    this.selectedRowKeys = selectedRowKeys;
                },
                onSelect: (record, selected, selectedRows, nativeEvent) => {
                    this.loading = true;
                    console.log('【radioRowSelection】-record：', record, this.videoBrand);

                    if (this.videoBrand == 12) {  //海康
                        if (this.videoWindCameraMap.get(this.nextVideoWindNo)) {
                            let c = this.videoWindCameraMap.get(this.nextVideoWindNo);
                            this.videoStop(this.nextVideoWindNo, c);
                            this.videoCameraWindMap.delete(c.id);
                            this.videoWindCameraMap.delete(this.nextVideoWindNo);
                        }
                        console.log('海康:', this.nextVideoWindNo, record);
                        this.loading = true;

                        this.videoPlay(this.nextVideoWindNo, record);   // 开始播放
                        this.videoCameraWindMap.set(record.id, this.nextVideoWindNo);
                        this.videoWindCameraMap.set(this.nextVideoWindNo, record);

                        this.curtVideoWindNo = this.nextVideoWindNo;
                        this.nextVideoWindNo = this.getNextVideoWindNo(this.curtVideoWindNo);
                        this.loading = false;
                    } else if (this.videoBrand == 14) {//萤石云
                        if (this.cameraId > 0) {
                            console.log(this.$refs["ysplayer"]);
                            this.$refs["ysplayer"].stop();
                        }
                        this.cameraId = 0;
                        setTimeout(() => {
                            this.cameraId = record.id;
                            this.loading = false;
                        }, 100);
                    } else if (this.videoBrand == 13) {    //本地推流
                        this.proxyVideoPlay(record);
                        this.loading = false;
                    } else {
                        this.loading = false;
                    }
                },
                type: 'radio',
            };
        },
        rowSelection() {
            const { selectedRowKeys } = this;
            return {
                hideDefaultSelections: true,
                selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                    this.selectedRows = selectedRows;
                    this.selectedRowKeys = selectedRowKeys;
                },
                onSelect: (record, selected, selectedRows, nativeEvent) => {
                    console.log(`record: `, record, 'selected: ', selected, 'selectedRows: ', selectedRows, 'nativeEvent: ', nativeEvent);
                    if (this.videoBrand > 0) {


                        this.loading = true;
                        if (selected) {
                            if (this.videoWindCameraMap.get(this.nextVideoWindNo)) {
                                let c = this.videoWindCameraMap.get(this.nextVideoWindNo);
                                this.videoStop(this.nextVideoWindNo, c);
                                this.videoCameraWindMap.delete(c.id);
                                this.videoWindCameraMap.delete(this.nextVideoWindNo);
                                console.log("bf selectedRowKeys", this.selectedRowKeys);
                                this.selectedRowKeys.splice(this.selectedRowKeys.indexOf(c.id), 1);
                                console.log("af selectedRowKeys", this.selectedRowKeys);
                                this.selectedRows.splice(this.selectedRows.indexOf(c), 1)
                            }

                            this.videoPlay(this.nextVideoWindNo, record);
                            this.videoCameraWindMap.set(record.id, this.nextVideoWindNo);
                            this.videoWindCameraMap.set(this.nextVideoWindNo, record);

                            this.curtVideoWindNo = this.nextVideoWindNo;
                            this.nextVideoWindNo = this.getNextVideoWindNo(this.curtVideoWindNo);

                        } else {
                            let windNo = this.videoCameraWindMap.get(record.id);
                            this.videoStop(windNo, record);
                            this.videoCameraWindMap.delete(record.id);
                            this.videoWindCameraMap.delete(windNo);
                            this.curtVideoWindNo = windNo;
                            this.nextVideoWindNo = windNo;
                        }
                        console.log("*******************************************************");
                        console.log("videoCameraWindMap", this.videoCameraWindMap);
                        console.log("videoWindCameraMap", this.videoWindCameraMap);
                        console.log("curtVideoWindNo", this.curtVideoWindNo);
                        console.log("nextVideoWindNo", this.nextVideoWindNo);
                        console.log("*******************************************************");
                    }
                },
                onSelectAll: (selected, selectedRows, changeRows) => {
                    if (this.videoBrand > 0) {
                        if (selected) {

                            let keys = new Array();
                            let rows = new Array();
                            let num = this.videoWindNum;
                            if (selectedRows.length < this.videoWindNum)
                                num = selectedRows.length;

                            if (!this.isVideoLogin) {
                                this.checkLogin(selectedRows[0], 2, 0, selectedRows, this.videoPlayList);
                                for (let i = 0; i < num; i++) {
                                    const record = selectedRows[i];
                                    keys.push(record.id);
                                    rows.push(record);
                                }
                            } else {

                                for (let i = 0; i < num; i++) {
                                    const record = selectedRows[i];
                                    setTimeout(() => {
                                        this.videoPlay(this.nextVideoWindNo * 1, record);
                                        keys.push(record.id);
                                        rows.push(record);
                                        this.nextVideoWindNo = this.getNextVideoWindNo(this.nextVideoWindNo);
                                    }, 100);

                                }
                            }


                            this.selectedRows = rows;
                            this.selectedRowKeys = keys;
                        } else {
                            this.stopAll();
                        }
                    }
                },
                type: this.chktype,
            };
        }
    },
    activated() {
        // if(document.getElementById('cbinfo'))document.getElementById('cbinfo').innerHTML = '';
        // this.initPage();
        let sprotocol = location.protocol;
        if (sprotocol === 'https:') {
            this.protocol = 2;
        } else {
            this.protocol = 1;
        }
        console.log('live location.protocol', this.protocol);
        if (this.ploaded == 0)
            this.ploaded = 1;

    },
    mounted() {
        let txt = this.$t('video.viddata040');
        this.screen4 = txt.replace('{num}', '4');
        this.screen9 = txt.replace('{num}', '9');
        this.screen16 = txt.replace('{num}', '16');
        if (this.ploaded == 0)
            this.ploaded = 1;
        // 调用表格高度计算方法
        this.pageSize();
        // 根据窗口变化计算表格高度
        window.addEventListener("resize", this.pageSize);

    },
    watch: {
        "ploaded": {
            handler(val) {
                if (val == 1) {
                    this.loading = true;
                    let sprotocol = location.protocol;
                    if (sprotocol === 'https:') {
                        this.protocol = 2;
                    } else {
                        this.protocol = 1;
                    }
                    console.log('live location.protocol', this.protocol);
                    this.initPage();
                }
            },
            deep: true,
            immediate: true,
        }
    },
    deactivated() {
        console.log('deactivated', moment().format('YYYY/MM/DD HH:mm:ss.SSS'));
        this.close();
        this.ploaded = 0;
        // window.removeEventListener("resize", this.pageSize);
    },
    destroyed() {
        console.log('destroyed', moment().format('YYYY/MM/DD HH:mm:ss.SSS'));
        this.close();
        window.removeEventListener("resize", this.pageSize);
        this.ploaded = 0;
    },
    beforeDestroy() {

    },
    methods: {
        removeCheck() {
            console.log('removeCheck')
            this.selectedRowKeys = [];
        },
        proxyVideoPlay(record) {
            this.$refs["player"].destroy();
            this.loading = true;
            proxySave(this.cameraGroupId, record)
                .then((res) => {
                    console.log("res", res);
                    if (res.code === 0) {
                        // getStreamInfo(this.cameraGroupId,record.id)
                        //     .then((res) => {
                        //         });
                        console.log("res", res);
                        let url = "ws://10.98.234.210:80/" + this.cameraGroupId + "/" + record.id + ".live.flv";
                        if (this.protocol === 2) {
                            // url = "wss://10.98.234.210:443/" + this.cameraGroupId + "/" + record.id + ".live.flv";
                            url = "wss://" + location.hostname + ":443/localeVideoProxy/" + this.cameraGroupId + "/" + record.id + ".live.flv";
                        }
                        this.$refs["player"].play(url);
                    }
                    this.loading = false;
                }).catch((err) => {
                    console.log(err);
                    this.$message.error('摄像头播放失败，请检查摄像头是否异常。');
                    this.selectedRowKeys = [];
                    this.loading = false;

                });
        },
        destroy(idx) {
            if (this.videoBrand === 13) {
                if (this.selectedRowKeys && this.selectedRowKeys.length > 0) {
                    this.$refs["player"].destroy();
                    this.selectedRowKeys = [];
                }
            } else if (this.videoBrand === 14) {
                if (this.selectedRowKeys && this.selectedRowKeys.length > 0) {
                    this.$refs["ysplayer"].destroy();
                    this.selectedRowKeys = [];
                }
            }

            console.log(idx);
            //   this.clear(idx.substring(idx.length - 1))
        },
        shot(e) {
            // console.log(e)
            // send({code:'image',data:e})
            var base64ToBlob = function (code) {
                let parts = code.split(';base64,');
                let contentType = parts[0].split(':')[1];
                let raw = window.atob(parts[1]);
                let rawLength = raw.length;
                let uInt8Array = new Uint8Array(rawLength);
                for (let i = 0; i < rawLength; ++i) {
                    uInt8Array[i] = raw.charCodeAt(i);
                }
                return new Blob([uInt8Array], {
                    type: contentType
                });
            };
            let aLink = document.createElement('a');
            let blob = base64ToBlob(e); //new Blob([content]);
            let evt = document.createEvent("HTMLEvents");
            evt.initEvent("click", true, true); //initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
            aLink.download = '截图';
            aLink.href = URL.createObjectURL(blob);
            aLink.click();
        },
        zoomEnable() {
            var oWndInfo = WebVideoCtrl.I_GetWindowStatus(this.g_iWndIndex),
                szInfo = "";
            if (this.zoom == 0) {
                if (oWndInfo != null) {
                    var iRet = WebVideoCtrl.I_EnableEZoom();
                    if (0 == iRet) {
                        szInfo = "启用电子放大成功！";
                        this.zoom = 1;
                    } else {
                        szInfo = "启用电子放大失败！";
                    }
                    this.showCBInfo(oWndInfo.szDeviceIdentify + " " + szInfo);
                }

            } else {
                if (oWndInfo != null) {
                    var iRet = WebVideoCtrl.I_DisableEZoom();
                    if (0 == iRet) {
                        szInfo = "禁用电子放大成功！";
                        this.zoom = 0;
                    } else {
                        szInfo = "禁用电子放大失败！";
                    }
                    this.showCBInfo(oWndInfo.szDeviceIdentify + " " + szInfo);
                }
            }
        },
        reconnect(szDeviceIdentify) {
            let that = this;
            WebVideoCtrl.I_Reconnect(szDeviceIdentify, {
                success: function (xmlDoc) {
                    that.stopAll();
                    that.selectedRows = new Array();
                    that.selectedRowKeys = new Array();
                    that.showCBInfo(szDeviceIdentify + "重连成功。");
                },
                error: function (status, xmlDoc) {
                    if (401 == status) {// 无插件方案，重启后session已失效，程序执行登出，从已登录设备中删除
                        that.clickLogout();
                    } else {
                        setTimeout(function () { that.reconnect(szDeviceIdentify); }, 5000);
                    }
                }
            });
        },
        videoRecorder() {
            if (this.g_szRecordType == '') {
                this.startRecord('realplay');
            } else {
                this.stopRecord(this.g_szRecordType, this.recordWind);
            }
        },
        startRecord(szType) {
            let that = this;
            let szInfo = "";
            let oWndInfo = WebVideoCtrl.I_GetWindowStatus(this.g_iWndIndex);

            if (oWndInfo != null) {
                let camera = this.videoWindCameraMap.get(this.g_iWndIndex);
                if (!camera) {
                    return;
                }
                let szFileName = camera.name + "_" + moment().format('YYYYMMDDHHmmssSSS');;

                WebVideoCtrl.I_StartRecord(szFileName, {
                    iWndIndex: parseInt(this.g_iWndIndex),
                    bDateDir: true, //是否生成日期文件
                    success: function () {
                        if ('realplay' === szType) {
                            szInfo = "开始录像成功！";
                        } else if ('playback' === szType) {
                            szInfo = "开始剪辑成功！";
                        }
                        that.g_szRecordType = szType;
                        that.recordWind = that.g_iWndIndex;
                        console.log(oWndInfo.szDeviceIdentify + " " + szInfo + " " + that.g_szRecordType + " " + that.recordWind);
                    },
                    error: function () {
                        if ('realplay' === szType) {
                            szInfo = "开始录像失败！";
                        } else if ('playback' === szType) {
                            szInfo = "开始剪辑失败！";
                        }
                        console.log(oWndInfo.szDeviceIdentify + " " + szInfo);
                    }
                });
            }
        },
        stopRecord(szType, iWndIndex) {
            if ("undefined" === typeof iWndIndex) {
                iWndIndex = this.g_iWndIndex;
            }
            let szInfo = "";
            let oWndInfo = WebVideoCtrl.I_GetWindowStatus(parseInt(iWndIndex));
            console.log('stopRecord', oWndInfo);
            let that = this;
            if (oWndInfo != null) {
                WebVideoCtrl.I_StopRecord({
                    iWndIndex: parseInt(iWndIndex),
                    success: function () {
                        if ('realplay' === szType) {
                            szInfo = "停止录像成功！";
                        } else if ('playback' === szType) {
                            szInfo = "停止剪辑成功！";
                        }
                        console.log(oWndInfo.szDeviceIdentify + " " + szInfo + " " + that.g_szRecordType + " " + that.recordWind);
                    },
                    error: function () {
                        if ('realplay' === szType) {
                            szInfo = "停止录像失败！";
                        } else if ('playback' === szType) {
                            szInfo = "停止剪辑失败！";
                        }
                        console.log(oWndInfo.szDeviceIdentify + " " + szInfo);
                    }
                });
                that.g_szRecordType = "";
                that.recordWind = -1;

            }
        },
        getLocalCfg() {
            let xmlDoc = WebVideoCtrl.I_GetLocalCfg();
            console.log("getLocalCfg xmlDoc", xmlDoc);
            if (xmlDoc != null) {
                const xml = this.$x2js.dom2js(xmlDoc);
                console.log("getLocalCfg", xml);
                return xml;
            }

            return undefined;
        },
        capturePic() {
            let szInfo = "";
            let oWndInfo = WebVideoCtrl.I_GetWindowStatus(this.g_iWndIndex);
            if (oWndInfo != null) {
                // let xmlDoc = WebVideoCtrl.I_GetLocalCfg();
                let szCaptureFileFormat = "0";
                // if (xmlDoc != null) {
                //     const xml = that.$x2js.dom2js(xmlDoc);
                //     szCaptureFileFormat = xml.CaptureFileFormat;
                // }

                let xml = this.getLocalCfg();
                if (xml != undefined) {
                    szCaptureFileFormat = xml.CaptureFileFormat;
                }
                let camera = this.videoWindCameraMap.get(this.g_iWndIndex);
                //let szChannelID = camera.recorderChannelNo;

                let szPicName = camera.name + "_" + moment().format('YYYYMMDDHHmmssSSS');
                szPicName += ("0" === szCaptureFileFormat) ? ".jpg" : ".bmp";

                WebVideoCtrl.I2_CapturePic(szPicName, {
                    bDateDir: true  //是否生成日期文件
                }).then(function () {
                    szInfo = "抓图成功！";
                    console.log(oWndInfo.szDeviceIdentify + " " + szPicName + " " + szInfo);
                }, function () {
                    szInfo = "抓图失败！";
                    console.log(oWndInfo.szDeviceIdentify + " " + szPicName + " " + szInfo);
                });
            }
        },
        fullscreen() {
            WebVideoCtrl.I_FullScreen(true);
            this.showCBInfo(" 全屏设置！");
        },
        playwind(wind) {
            let num = parseInt(wind);
            let res = WebVideoCtrl.I_ChangeWndNum(parseInt(num));
            if (res === 0) {
                this.opts.iWndowType = num;
                this.videoWindNum = num * num;
                this.nextVideoWindNo = 0;
                this.curtVideoWindNo = 0;
                this.g_iWndIndex = 0;
                if (num == 1) {
                    //this.chktype = 'radio';
                    this.crowSelection = this.radioRowSelection;
                } else {
                    this.crowSelection = this.rowSelection;
                    // this.chktype = 'checkbox';
                }
            }
            this.showCBInfo(" 画面分割:" + wind + '*' + wind + "！ res=" + res + ' ;videoWindNum=' + this.videoWindNum);
        },
        close() {
            if (this.videoBrand != 0) {
                this.stopAll();
                this.clickLogout();
                this.destroy();
            }
        },
        showCBInfo(szInfo) {
            szInfo = "<div>" + moment().format('YYYY/MM/DD HH:mm:ss.SSS') + " " + szInfo + "</div>";
            console.log('showCBInfo', szInfo);
            // if(document.getElementById('cbinfo'))
            //     document.getElementById('cbinfo').innerHTML = szInfo+document.getElementById('cbinfo').innerHTML;
        },
        videoInit(options) {
            let that = this;
            //检查浏览器是否支持无插件
            let bol = window.WebVideoCtrl.I_SupportNoPlugin();
            console.log('videoInit I_SupportNoPlugin', bol);
            this.showCBInfo('检查浏览器是否支持无插件:' + bol);
            options.bNoPlugin = bol;
            //检查插件是否已经安装过
            let iRet = window.WebVideoCtrl.I_CheckPluginInstall();
            console.log('videoInit I_CheckPluginInstall', iRet);
            this.showCBInfo('检查插件是否已经安装过:' + iRet);
            if (-1 == iRet) {
                this.$message.error($t("video.viddata062"));
                return;
            }
            // 初始化插件参数及插入插件
            console.log('[videoInit-WebVideoCtrl]----666:', WebVideoCtrl);
            WebVideoCtrl.I_InitPlugin(options.width, options.height, {
                // bWndFull: true,//options.bWndFull,     //是否支持单窗口双击全屏，默认支持 true:支持 false:不支持 。（无插件默认 true 不支持修改）
                // iPackageType: options.iPackageType,     //2:PS 11:MP4
                szColorProperty: options.szColorProperty,//"plugin-background:0000ff; sub-background:0000ff; sub-border:00ffff; sub-border-select:0000ff",
                iWndowType: options.iWndowType,
                bNoPlugin: options.bNoPlugin,
                bDebugMode: true,
                cbSelWnd: function (xmlDoc) {
                    //console.log('videoInit xmlDoc',xmlDoc);
                    const xml = that.$x2js.dom2js(xmlDoc)
                    //console.log('videoInit xml2js',xml);
                    that.g_iWndIndex = xml.RealPlayInfo.SelectWnd;
                    that.nextVideoWindNo = that.g_iWndIndex;
                    let szInfo = "当前选择的窗口编号：" + that.g_iWndIndex;
                    that.showCBInfo(szInfo);
                },
                cbInitPluginComplete: function () {
                    WebVideoCtrl.I_InsertOBJECTPlugin("divRealPlayPlugin");
                    console.log('[videoInit-cbInitPluginComplete]----666:', that.cameraList[0]);
                    if (!that.isVideoLogin && that.cameraList.length > 0 && that.cameraList[0] != null) {
                        that.checkLogin(that.cameraList[0]);
                    }


                    // // 检查插件是否最新
                    // if (-1 == WebVideoCtrl.I_CheckPluginVersion()) {
                    //     alert("检测到新的插件版本，双击开发包目录里的WebComponentsKit.exe升级！");
                    //     return;
                    // }
                }
            });
        },
        checkLogin(camera, act, windNo, list, callback) {
            this.loading = true;
            let that = this;
            console.log('checkLogin', camera, act, windNo, list, callback);
            //&& this.recorder==null
            if (camera) {
                this.recorder = {
                    ip: camera.recorderIpAddress,
                    port: camera.recorderConnectPort,
                    userid: camera.monitoringUserId,
                    pass: camera.monitoringUserPassword,
                    brand: camera.cameraType,
                };
            }

            if ("" == this.recorder.ip || "" == this.recorder.port) {
                return false;
            }
            if (this.protocol === 2) {
                this.recorder.port = 443;
            }
            console.log("I_Login recorder", this.recorder);
            this.getLocalCfg();
            let szDeviceIdentify = this.recorder.ip + "_" + this.recorder.port;
            console.log("I_Login recorder", this.recorder, szDeviceIdentify);
            console.log('[videoInit-checkLogin]----666:', this.recorder.ip, this.protocol, this.recorder.port, this.recorder.userid, this.recorder.pass);
            // protocol- http 协议，1 表示 http 协议 2 表示 https 协议
            let iRet = WebVideoCtrl.I_Login(
                this.recorder.ip,
                this.protocol,
                this.recorder.port,
                this.recorder.userid,
                this.recorder.pass, {
                cgi: 1,
                async: false,
                success: function (xmlDoc) {
                    console.log('[WebVideoCtrl.I_Login]-登录(success)回调:', xmlDoc);
                    that.recorderIP = szDeviceIdentify;
                    that.isVideoLogin = true;
                    that.loginIpPort = szDeviceIdentify;
                    that.showCBInfo(szDeviceIdentify + " 登录成功！");
                    console.log("I_Login success", windNo, act);
                    if (act != undefined) {
                        if (act === 1 && windNo != undefined && callback != undefined) {
                            console.log("play one", windNo, camera);
                            callback(windNo, camera);
                        }
                        if (act === 1 && list != undefined && callback != undefined) {
                            console.log("play list", list);
                            callback(list);
                        }
                    }
                },
                error: function (status, xmlDoc) {
                    console.log('[WebVideoCtrl.I_Login]-登录(error)回调:', status, xmlDoc);
                    that.isVideoLogin = false;
                    that.loginIpPort = "";
                    //fncShowMsgDlg(2, "录像机登录连接失败！", "");
                    console.log("I_Login error", status, xmlDoc);
                    that.$message.error(that.$t("video.viddata070"));// '录像机登录连接失败！');
                    that.showCBInfo(szDeviceIdentify + "登录失败！" + status);
                    //fncWaitingDisable();
                }
            });

            if (-1 == iRet) {
                that.recorderIP = szDeviceIdentify;
                that.isVideoLogin = true;
                that.loginIpPort = szDeviceIdentify;
                console.log("I_Login A R L:", callback, windNo, camera);

                if (windNo != undefined && callback != undefined && camera) {
                    callback(windNo, camera);
                }
                // that.$message.error(that.$t("video.viddata069"));// '录像机已登录过！');
                this.showCBInfo(szDeviceIdentify + " 已登录过！");
            }
            this.loading = false;
        },
        clickLogout() {
            let res = 0;
            if (this.recorder) {
                let szDeviceIdentify = this.recorder.ip + "_" + this.recorder.port; //$("#ip").val(),
                console.log('szDeviceIdentify:', szDeviceIdentify);
                let szInfo = "";
                if (null == szDeviceIdentify) {
                    return;
                }
                let iRet = WebVideoCtrl.I_Logout(szDeviceIdentify);
                if (0 == iRet) {
                    this.isVideoLogin = false;
                    this.loginIpPort = "";
                    this.recorder = null;
                    szInfo = "退出成功！";
                } else {
                    szInfo = "退出失败！";
                    this.showCBInfo(szDeviceIdentify + szInfo);
                    // this.$message.error('录像机登出失败 ！');

                }
                res = iRet;
                console.log(szDeviceIdentify + " " + szInfo);
                this.showCBInfo(szDeviceIdentify + " " + szInfo);
            }
            return res;
        },
        play(szDeviceIdentify, iRtspPort, bZeroChannel, iChannelID, iStreamType, windNo, camera) {
            let that = this;
            let szInfo = '';
            console.log('Play Info:', szDeviceIdentify, iRtspPort, bZeroChannel, iChannelID, iStreamType, windNo, camera);
            WebVideoCtrl.I_StartRealPlay(szDeviceIdentify, {
                iWndIndex: windNo,
                iStreamType: iStreamType,
                iChannelID: iChannelID,
                bZeroChannel: bZeroChannel,
                iRtspPort: iRtspPort,
                success: function () {
                    szInfo = "窗口" + windNo + "预览成功！";
                    console.log(szDeviceIdentify + " " + szInfo);
                    that.showCBInfo(szDeviceIdentify + " " + szInfo);

                    that.videoCameraWindMap.set(camera.id, windNo);
                    that.videoWindCameraMap.set(windNo, camera);

                    that.curtVideoWindNo = windNo;
                    that.nextVideoWindNo = that.getNextVideoWindNo(windNo);
                    that.loading = false;
                },
                error: function (status, xmlDoc) {
                    if (403 === status) {
                        // szInfo = that.$t("video.viddata068");// "设备不支持Websocket取流！";
                        szInfo = that.$t("video.viddata067");
                    } else {
                        szInfo = that.$t("video.viddata067");// "预览失败！";
                        let szDeviceIdentify = that.recorder.ip + "_" + that.recorder.port;
                        that.reconnect(szDeviceIdentify);
                    }
                    console.log(szDeviceIdentify + " " + szInfo, status);
                    that.$message.error(szInfo);
                    that.loading = false;
                    that.showCBInfo(szDeviceIdentify + " " + szInfo);

                }
            });
        },
        videoPlayList(cameras) {
            let num = cameras.length > this.videoWindNum ? this.videoWindNum : cameras.length;
            for (let i = 0; i < num; i++) {
                const element = cameras[i];
                this.videoPlay(i, element);
            }
        },

        vstopAndout(szDeviceIdentify, windNo, camera, replay, ncarmera) {
            console.log('vstop 1', szDeviceIdentify, windNo, camera);
            let oWndInfo = WebVideoCtrl.I_GetWindowStatus(windNo);
            console.log('vstop 2', oWndInfo);
            let szInfo = "";
            let that = this;
            if (oWndInfo != null) {
                WebVideoCtrl.I_Stop({
                    iWndIndex: windNo,
                    success: function () {
                        // fncWaitingDisable();
                        that.videoCameraWindMap.delete(camera.id);
                        that.videoWindCameraMap.delete(windNo);

                        console.log("video Map  info", that.videoCameraWindMap, that.videoWindCameraMap);
                        that.loading = false;
                        that.nextVideoWindNo = windNo;
                        that.g_szRecordType = "";
                        that.recordWind = -1;
                        let iRet = WebVideoCtrl.I_Logout(szDeviceIdentify);
                        if (0 == iRet) {
                            that.isVideoLogin = false;
                            that.loginIpPort = "";
                            that.recorder = null;
                            szInfo = "退出成功！";
                            that.showCBInfo(szDeviceIdentify + " " + szInfo);
                            if (replay) {
                                that.checkLogin(ncarmera, 1, windNo, null, that.videoPlay);
                            }
                        }

                        szInfo = "停止预览成功！";
                        that.showCBInfo(szDeviceIdentify + " " + szInfo);

                    },
                    error: function () {
                        szInfo = "停止预览失败！";
                        that.showCBInfo(szDeviceIdentify + " " + szInfo);
                        that.loading = false;
                        that.$message.error(that.$t('video.viddata066'));//'停止预览失败！');
                    }
                });
            }
            if (!camera) {
                let iRet = WebVideoCtrl.I_Logout(szDeviceIdentify);
                if (0 == iRet) {
                    that.isVideoLogin = false;
                    that.loginIpPort = "";
                    that.recorder = null;
                    szInfo = "退出成功！";
                    that.showCBInfo(szDeviceIdentify + " " + szInfo);
                    if (replay) {
                        that.checkLogin(ncarmera, 1, windNo, null, that.videoPlay);
                    }
                }
            }
            // else{
            //     that.loading = false;
            //     that.nextVideoWindNo = windNo;
            //     // fncWaitingDisable();
            //     that.checkLogin(camera,1,windNo,null,that.videoPlay);
            // }
        },

        videoPlay(windNo, camera) {
            console.log("videoPlay窗口播放", windNo, camera);
            if (!camera) {
                return;
            }
            if (this.protocol === 2) {
                camera.recorderConnectPort = 443;
            }
            let szDeviceIdentify = camera.recorderIpAddress + "_" + camera.recorderConnectPort;
            console.log("szDeviceIdentify & loginIpPort", windNo, szDeviceIdentify, this.loginIpPort);
            console.log('是否登录:', this.isVideoLogin);
            if (szDeviceIdentify !== this.loginIpPort) {
                if (this.isVideoLogin) {
                    console.log("playCamera", windNo, this.videoWindCameraMap);
                    let playCamera = this.videoWindCameraMap.get(windNo + '');
                    this.vstopAndout(this.loginIpPort, windNo, playCamera, true, camera);
                } else {
                    this.checkLogin(camera, 1, windNo, null, this.videoPlay);
                }
                return;
            } else {

                if (!this.isVideoLogin) {
                    this.checkLogin(camera, 1, windNo, null, this.videoPlay);
                    return;
                }
            }

            let iRtspPort = this.rtspPort;
            let bZeroChannel = this.zeroChannel;
            let iChannelID = parseInt(camera.recorderChannelNo);
            let iStreamType = this.streamType;
            let szInfo = "";

            if (null == szDeviceIdentify) {
                return;
            }
            let that = this;
            let oWndInfo = WebVideoCtrl.I_GetWindowStatus(windNo);
            console.log('videoPlay', windNo, oWndInfo);

            if (oWndInfo != null) {// 已经在播放了，先停止
                WebVideoCtrl.I_Stop({
                    iWndIndex: oWndInfo.iIndex,
                    success: function () {
                        that.play(szDeviceIdentify, iRtspPort, bZeroChannel, iChannelID, iStreamType, windNo, camera);
                    },
                    error: function () {
                        szInfo = windNo + " 停止预览失败！";
                        that.loading = false;
                        that.play(szDeviceIdentify, iRtspPort, bZeroChannel, iChannelID, iStreamType, windNo, camera);
                        that.showCBInfo(szDeviceIdentify + " " + szInfo);
                    }
                });

            } else {
                this.play(szDeviceIdentify, iRtspPort, bZeroChannel, iChannelID, iStreamType, windNo, camera);
            }
        },
        videoStop(windNo, camera) {
            console.log('videoStop', windNo, camera);
            let oWndInfo = WebVideoCtrl.I_GetWindowStatus(windNo);
            console.log('videoStop', oWndInfo);
            let szInfo = "";
            let that = this;
            let szDeviceIdentify = camera.recorderIpAddress + "_" + camera.recorderConnectPort;
            if (oWndInfo != null) {
                WebVideoCtrl.I_Stop({
                    iWndIndex: windNo,
                    success: function () {
                        szInfo = "停止预览成功！";
                        that.showCBInfo(szDeviceIdentify + " " + szInfo);
                        // fncWaitingDisable();
                        that.videoCameraWindMap.delete(camera.id);
                        that.videoWindCameraMap.delete(windNo);
                        that.loading = false;
                        that.nextVideoWindNo = windNo;
                        that.g_szRecordType = "";
                        that.recordWind = -1;
                    },
                    error: function () {
                        szInfo = "停止预览失败！";
                        that.showCBInfo(szDeviceIdentify + " " + szInfo);
                        that.loading = false;
                        that.$message.error(that.$t('video.viddata066'));//'停止预览失败！');
                    }
                });
            } else {
                that.loading = false;
                that.nextVideoWindNo = windNo;
                // fncWaitingDisable();
            }
        },
        initPage() {
            this.getCameraSet();
        },
        cameraGrChange(e) {
            this.stopAll();
            this.getCameraList();
            if (this.videoBrand != 12)
                this.$refs["player"].destroy();
        },
        pageSize() {
            let height = this.$refs.info.clientHeight;
            // let tbheight = this.$refs.videotb.clientHeight;
            // console.log("tbheight",tbheight);
            let tableHeight = height - 160;
            // console.log("tableHeight",tableHeight);
            this.tscroll.y = tableHeight;
            let width = this.$refs.info.clientWidth;
            //this.vwidth = (width - 400) + "px";
            this.vhigh = (height - 60) + "px";
            if (this.videoBrand === 12) {
                this.vhigh = (height - 60) + "px";
            } else {
                this.vhigh = (height - 20) + "px";
            }
            //this.tstyle.height = tableHeight+"px";
            // if(tableHeight*1<tbheight*1){
            // }
            // let width = this.$refs.info.clientWidth;
            // this.tscroll.x = width;
        },
        getCameraSet() {
            this.loading = true;
            getVideoCameraSet(this.siteId)
                .then((res) => {
                    console.log("res", res);
                    if (res.errorCode === '00') {
                        this.cameraGroupList = res.data;
                        if (this.cameraGroupList && this.cameraGroupList.length > 0) {
                            this.cameraGroupId = this.cameraGroupList[0].id;
                            this.getCameraList();
                        } else {
                            this.loading = false;
                        }
                    } else {
                        this.loading = false;
                    }
                }).catch((err) => {
                    console.log(err);
                    this.loading = false;
                });
        },
        getCameraList() {
            this.loading = true;
            getVideoCameraList(this.cameraGroupId)
                .then((res) => {
                    console.log("GetCameraList res", res);
                    if(res.errorCode==='00'){
                        this.cameraList = res.data;
                        console.log("GetCameraList CameraList", this.cameraList);
                        if(this.videoBrand==0 && this.cameraList[0].cameraType>0){
                            this.videoBrand = this.cameraList[0].cameraType;
                        }
                        for (let i = 0; i < this.cameraList.length; i++) {
                           this.cameraList[i].key=(i+1);
                        }
                        this.selectedRows = new Array();
                        this.selectedRowKeys = new Array();

                        if(this.videoBrand == 12){ //海康摄像头
                            let height = this.$refs.info.clientHeight;
                            this.vhigh  = (height-60)  + "px";
                            if(this.recorderIP == ''){
                                console.log('[getVideoCameraList] ----- 判断(this.recorderIP):',this.videoBrand, this.opts);
                                if(this.videoTag==0 && this.videoBrand == 12){
                                     this.videoTag = this.videoBrand;
                                     this.videoInit(this.opts);
                                }else if(this.videoBrand==12 && !this.isVideoLogin){
                                     this.checkLogin(this.cameraList[0]);
                                }else{
                                    this.loading = false;
                                }
                            }else{
                                console.log('[getVideoCameraList] ----- 判断(else)');
                                let ip = this.cameraList[0].recorderIpAddress + "_" + this.cameraList[0].recorderConnectPort;
                                console.log('gr change ip'+ip)
                                if(this.recorderIP != ip){
                                    let res = this.clickLogout();
                                    if(res==0){
                                        this.recorder = {
                                            ip:this.cameraList[0].recorderIpAddress,
                                            port:this.cameraList[0].recorderConnectPort,
                                            userid: this.cameraList[0].monitoringUserId,
                                            pass:this.cameraList[0].monitoringUserPassword,
                                            brand:this.cameraList[0].cameraType,
                                        };
                                        this.checkLogin(this.cameraList[0]);
                                    }else{
                                        this.loading = false;
                                    }
                                }else{
                                    this.loading = false;
                                }
                            }
                        }
                        this.loading = false;
                    }else{
                        this.loading = false;
                    }
                }).catch((err) => {
                    console.log(err);
                    this.loading = false;
                });
        },
        getNextVideoWindNo(crtNo) {
            if ((crtNo * 1 + 1) < this.videoWindNum * 1) {
                return crtNo * 1 + 1;
            } else {
                return 0;
            }
        },
        stopAll() {
            for (let i = 0; i < this.videoWindNum; i++) {
                let oWndInfo = WebVideoCtrl.I_GetWindowStatus(i);
                //if(oWndInfo!=null){
                WebVideoCtrl.I_Stop({
                    iWndIndex: i,
                    success: function () {
                        console.log("窗口" + i + "关闭成功！");
                    },
                    error: function () {
                        console.log("窗口" + i + "关闭失败！");
                    }
                });
                //}
            }
            this.nextVideoWindNo = 0;
            this.curtVideoWindNo = 0;
            this.videoWindCameraMap = new Map();
            this.videoCameraWindMap = new Map();
            this.g_iWndIndex = 0;
            this.selectedRows = new Array();
            this.selectedRowKeys = new Array();
        },
    }
}
</script>
<style scoped>
#videoLive {
    width: 100%;
    height: 100%;
}

.header {
    display: flex;
    justify-content: space-between;
}

.header-title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 18px;
}

.header-title::before {
    content: "";
    display: inline-block;
    width: 2px;
    height: 22px;
    margin-right: 20px;
    background-color: #7682ce;
}

.header-condition {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
}

.header-condition button {
    margin-bottom: 5px;
}

.header-condition span {
    margin-bottom: 5px;
}

.high {
    height: calc(100% - 10px);
}

.info {
    width: 100%;
    height: 100%;
    padding: 10px;
}

.video {
    width: 100%;
    height: 100%;
    padding: 10px;
}

.video-ys {
    width: 100%;
    height: 100%;
    padding: 10px;
    background: #000;
    text-align: center;
}

.plugin {
    width: 100%;
    height: 100%;
    padding: 0 10px;

}

.video-tool-box {
    margin: 0 10px;
    padding: 5px 0;
    background-color: black;
}

.video-tool-button {
    color: rgba(0, 0, 0, 1);
    background-color: white;
    border-color: white;
    text-shadow: none;
    box-shadow: none;
    margin-left: 5px;
}

.video-tool-button[disabled],
.video-tool-button[disabled]:hover {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
}

.video-tool-button-active {
    background-color: white;
    border-color: #d9d9d9;
    color: red;
    text-shadow: none;
    box-shadow: none;
    margin-left: 5px;
}

.video-tool-button:hover {
    background-color: #7682CE;
    border-color: #7682CE;
    color: rgba(255, 255, 255, 1);
    text-shadow: none;
    box-shadow: none;
}
</style>
